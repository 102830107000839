<script>
import RadialProgressBar from "vue-radial-progress";
import CalendarApiService from "@/services/calendarService";

export default {
  components: {
    RadialProgressBar
  },
  data() {
    return {
      modalShown: false,
      item: false,
      progressName: false,
      percentCompleted: 0,
      progressBarColor: '',
      response: null
    }
  },
  methods: {
    show(item) {
      this.item = item
      this.progressName = item.progress.name
      this.percentCompleted = item.percentCompleted
      this.progressBarColor = this.getProgressBarColor(item.percentCompleted);
      this.modalShown = true
      return new Promise((resolve, reject) => {
        this.response = (val) => {
          if(val == null) {
            reject();
          } else {
            resolve(val);  
          }          
        };
      }).finally(() => {
        this.modalShown = false;
      });
    },
    getProgressBarColor(percent) {
      if (percent == 1) {
        return "#34c38f";
      } else if (percent == 0) {
        return "rgba(52, 58, 64, 0.31)";
      } else {
        return "#f1b44c";
      }
    },
    // Need to get nextProgressToComplete from backend
    openCompleteStepsBeforeModal(item) {
      //get progress config
      this.isLoading = true;
      let progApp = item;
      CalendarApiService.getProgressConfig(item.progress.id).then((response) => {
        this.isLoading = false;

        let config = response.data.data;
        
        if (
          config.askToCompletePreviousProgress == true &&
          progApp.progress.id != progApp.workOrder.nextProgressToComplete.id
        ) {
          this.$refs.completeStepsBeforeModal.show().then(
            (userResponse) => {
              this.completeProgressAppointment(progApp, userResponse);
            },
            () => {}
          );
        } else {
          this.completeProgressAppointment(progApp, true);
        }
      });
    },
  }
}
</script>

<template>
  <b-modal
    v-model="modalShown"
    centered
  >
    <div class="d-flex justify-content-center align-items-center">
      <div class="d-flex justify-content-between align-items-center w-75">
        <div class="d-flex flex-column">
          <div class="d-flex mb-2">
            <span style="font-weight: 500;">
              {{ $t('general.dateStarted') }}
            </span>
            <div v-if="item.dateStarted" class="text-primary ml-2">
              {{ $dayjs(item.dateStarted).format("L") }}
            </div>
            <span v-else style="color: gray;" class="ml-2">
              -
            </span>
          </div>

          <div class="d-flex">
            <span style="font-weight: 500;">
              {{ $t('general.dateDone') }}
            </span>
            <div v-if="item.dateDone" class="text-primary ml-2">
              {{ $dayjs(item.dateDone).format("L") }}
            </div>
            <span v-else style="color: gray;" class="ml-2">
              -
            </span>
          </div>
        </div>
  
        <radial-progress-bar
          :diameter="100"
          :strokeWidth="8"
          :startColor="progressBarColor"
          :stopColor="progressBarColor"
          innerStrokeColor="#cfcfcf"
          :innerStrokeWidth="6"
          strokeLinecap="square"
          :completed-steps="percentCompleted * 100"
          :total-steps="100"
        >
          <span>
            {{ Math.floor(percentCompleted * 100) }} %
          </span>
        </radial-progress-bar>
      </div>
    </div>

    <template #modal-title>
      <div class="d-flex justify-content-center align-items-center">
        {{ progressName }}
        <i v-if="item.isLate" class='bx bx-time bx-tada pl-2' style='color:#ff0000' ></i>
      </div>
    </template>

    <template #modal-footer>
      <div class="d-flex flex-row justify-content-between w-100">
        <div>
          <b-button variant="light" class="mr-2 float-right" @click="response(false)">
            {{$t('general.cancel')}}
          </b-button>     
        </div>
        <div>
          <b-button variant="success" class="float-right" @click="response('complete')" :disabled="item.status == 'Completed' ? true : false">
            {{$t('general.complete')}}
          </b-button>
          <b-button variant="primary" class="mr-2 float-right" @click="response('start')" :disabled="item.status == 'Completed' ? true : false">
            {{$t('general.start')}}
          </b-button>
        </div>
      </div>
    </template>

  </b-modal>
</template>
