
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import CalendarApiService from "@/services/calendarService";
import WorkOrderGroupTypeahead from "@/components/widgets/workOrderGroupTypeahead";

export default {
  components: {
    DatePicker,
    WorkOrderGroupTypeahead,
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      selectedWorkOrders: [],
      newProductionDate: this.$dayjs().format("YYYY-MM-DD"),
      newDeliveryDate: this.$dayjs().format("YYYY-MM-DD"),
      newInstallationDate: this.$dayjs().format("YYYY-MM-DD"),
      workOrderTypes: [],
      templates: [],
      installers: [],
      shippers: [],
      properties: {
        productionDate: {
          followInterval: false,
          interval: 0,
          selected: false,
          newDate: this.$dayjs().format("YYYY-MM-DD"),
          originalDate: this.$dayjs().format("YYYY-MM-DD"),
        },
        deliveryDate: {
          followInterval: false,
          interval: 0,
          selected: false,
          newDate: this.$dayjs().format("YYYY-MM-DD"),
          originalDate: this.$dayjs().format("YYYY-MM-DD"),
        },
        installationDate: {
          followInterval: false,
          interval: 0,
          selected: false,
          newDate: this.$dayjs().format("YYYY-MM-DD"),
          originalDate: this.$dayjs().format("YYYY-MM-DD"),
        },
        template: {
          selected: false,
          value: null,
        },
        workOrderType: {
          selected: false,
          value: null,
        },
        workOrderGroup: {
          selected: false,
          value: null,
        },
        shipper: {
          selected: false,
          value: null,
        },
        installers: {
          selected: false,
          value: null,
        },
        roomName: {
          selected: false,
          value: null,
        },
        clientName: {
          selected: false,
          value: null,
        },
        contractor: {
          selected: false,
          value: null,
        },
        projectManager: {
          selected: false,
          value: null,
        },
      },
    };
  },
  created() {
    this.$bus.$on("open-bulk-change-dialog", this.openBulkChangeDialog);
  },
  beforeDestroy() {
    this.$bus.$off("open-bulk-change-dialog", this.openBulkChangeDialog);
  },
  computed: {
    productionDateInterval() {
      var interval = this.$dayjs(this.properties.productionDate.newDate).diff(
        this.properties.productionDate.originalDate,
        "day"
      );
      var sign = interval >= 0 ? "+" : "";
      return sign + interval;
    },
    deliveryDateInterval() {
      var interval = this.$dayjs(this.properties.deliveryDate.newDate).diff(
        this.properties.deliveryDate.originalDate,
        "day"
      );
      var sign = interval >= 0 ? "+" : "";
      return sign + interval;
    },
    installationDateInterval() {
      var interval = this.$dayjs(this.properties.installationDate.newDate).diff(
        this.properties.installationDate.originalDate,
        "day"
      );
      var sign = interval >= 0 ? "+" : "";
      return sign + interval;
    },
  },
  methods: {
    openBulkChangeDialog(selectedWorkOrders) {
      this.loadData();

      this.selectedWorkOrders = selectedWorkOrders;
      this.showModal = true;

      if (selectedWorkOrders.length > 0) {
        //assigner date production la plus petite
        var smallestProductionDate = selectedWorkOrders.sort((a, b) => {
          var date1 = this.$dayjs(a.productionDate);
          var date2 = this.$dayjs(b.productionDate);
          return date1.diff(date2);
        });
        this.properties.productionDate.newDate = this.$dayjs(
          smallestProductionDate[0].productionDate
        ).format("YYYY-MM-DD");
        this.properties.productionDate.originalDate =
          this.properties.productionDate.newDate;

        //assigner date livraison la plus petite
        var smallestDeliveryDate = selectedWorkOrders.sort((a, b) => {
          var date1 = this.$dayjs(a.deliveryDate);
          var date2 = this.$dayjs(b.deliveryDate);
          return date1.diff(date2);
        });
        this.properties.deliveryDate.newDate = this.$dayjs(
          smallestDeliveryDate[0].deliveryDate
        ).format("YYYY-MM-DD");
        this.properties.deliveryDate.originalDate =
          this.properties.deliveryDate.newDate;

        //assigner date installation la plus petite
        var smallestInstallationDate = selectedWorkOrders.sort((a, b) => {
          var date1 = this.$dayjs(a.installationDate);
          var date2 = this.$dayjs(b.installationDate);
          return date1.diff(date2);
        });
        this.properties.installationDate.newDate = this.$dayjs(
          smallestInstallationDate[0].installationDate
        ).format("YYYY-MM-DD");
        this.properties.installationDate.originalDate =
          this.properties.installationDate.newDate;
      }
    },
    async loadData() {
      this.isLoading = true;

      var p1 = CalendarApiService.getAllTemplatesList().then((response) => {
        this.templates = response.data.data;
      });

      var p2 = CalendarApiService.getAllJobTypes().then((response) => {
        this.workOrderTypes = response.data.data;
      });

      var p3 = CalendarApiService.getInstallers().then((response) => {
        this.installers = response.data.data;
      });

      var p4 = CalendarApiService.getShippers().then((response) => {
        this.shippers = response.data.data;
      });

      return Promise.all([p1, p2, p3, p4]).then(() => {
        this.isLoading = false;
      });
    },
    cancel() {
      this.close();
    },
    close() {
      this.resetPropeties();
      this.showModal = false;
      this.$emit("bulk-change-closed");
    },
    resetPropeties() {
      this.properties = {
        productionDate: {
          followInterval: false,
          interval: 0,
          selected: false,
          newDate: this.$dayjs().format("YYYY-MM-DD"),
          originalDate: this.$dayjs().format("YYYY-MM-DD"),
        },
        deliveryDate: {
          followInterval: false,
          interval: 0,
          selected: false,
          newDate: this.$dayjs().format("YYYY-MM-DD"),
          originalDate: this.$dayjs().format("YYYY-MM-DD"),
        },
        installationDate: {
          followInterval: false,
          interval: 0,
          selected: false,
          newDate: this.$dayjs().format("YYYY-MM-DD"),
          originalDate: this.$dayjs().format("YYYY-MM-DD"),
        },
        template: {
          selected: false,
          value: null,
        },
        workOrderType: {
          selected: false,
          value: null,
        },
        workOrderGroup: {
          selected: false,
          value: null,
        },
        shipper: {
          selected: false,
          value: null,
        },
        installers: {
          selected: false,
          value: null,
        },
        roomName: {
          selected: false,
          value: null,
        },
        clientName: {
          selected: false,
          value: null,
        },
        contractor: {
          selected: false,
          value: null,
        },
        projectManager: {
          selected: false,
          value: null,
        },
      };
    },
    applyBulkChange() {
      //simplifier structure work orders. envoyer seulement les infos pour les dates. Pour avoir les old dates de reference
      var workOrdersData = [];
      for (var workOrder of this.selectedWorkOrders) {
        var model = {
          idPublic: workOrder.idPublic,
          installationDate: workOrder.installationDate,
          deliveryDate: workOrder.deliveryDate,
          productionDate: workOrder.productionDate,
        };
        workOrdersData.push(model);
      }

      if (this.properties.productionDate.followInterval == true) {
        this.properties.productionDate.interval = this.productionDateInterval;
      }
      if (this.properties.deliveryDate.followInterval == true) {
        this.properties.deliveryDate.interval = this.deliveryDateInterval;
      }
      if (this.properties.installationDate.followInterval == true) {
        this.properties.installationDate.interval =
          this.installationDateInterval;
      }

      //envoyer empty idPublic pour retirer lattribut des work orders
      var data = {
        workOrders: workOrdersData,
        bulkChangeProperties: {
          productionDate:
            this.properties.productionDate.selected == true
              ? this.properties.productionDate
              : null,
          deliveryDate:
            this.properties.deliveryDate.selected == true
              ? this.properties.deliveryDate
              : null,
          installationDate:
            this.properties.installationDate.selected == true
              ? this.properties.installationDate
              : null,
          template:
            this.properties.template.selected == true
              ? this.properties.template.value ?? null
              : null,
          workOrderType:
            this.properties.workOrderType.selected == true
              ? this.properties.workOrderType.value ?? {
                  idPublic: "00000000-0000-0000-0000-000000000000",
                }
              : null,
          workOrderGroup:
            this.properties.workOrderGroup.selected == true
              ? this.properties.workOrderGroup.value ?? {
                  idPublic: "00000000-0000-0000-0000-000000000000",
                }
              : null,
          shipper:
            this.properties.shipper.selected == true
              ? this.properties.shipper.value ?? {
                  idPublic: "00000000-0000-0000-0000-000000000000",
                }
              : null,
          installers:
            this.properties.installers.selected == true
              ? this.properties.installers.value ?? []
              : null,
          roomName:
            this.properties.roomName.selected == true
              ? this.properties.roomName.value ?? ""
              : null,
          clientName:
            this.properties.clientName.selected == true
              ? this.properties.clientName.value ?? ""
              : null,
          contractor:
            this.properties.contractor.selected == true
              ? this.properties.contractor.value ?? ""
              : null,
          projectManager:
            this.properties.projectManager.selected == true
              ? this.properties.projectManager.value ?? ""
              : null,
        },
      };

      this.isLoading = true;
      CalendarApiService.workOrderBulkChange(data).then(() => {
        this.isLoading = false;
        this.$emit("bulk-change-applied");
        this.close();
      });
    },
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="$t('general.bulkChange')"
    title-class="text-black font-18"
    scrollable
    body-class="p-4"
    size="lg"
    no-close-on-backdrop
  >
    <div class="" style="display: relative">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        color="#f1b44c"
        :width="70"
        :height="70"
        loader="dots"
        :is-full-page="false"
      >
      </loading>
      <div class="mb-3">
        <p>{{ $t("general.selectPropertiesToChange") }}</p>
        {{ $t("general.selection") }}: {{ selectedWorkOrders.length }}
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="mb-4">
            <div style="display: flex; align-items: center">
              <b-form-checkbox
                class="mb-2"
                size="md"
                v-model="properties.productionDate.selected"
                >{{ $t("general.productionDate") }}</b-form-checkbox
              >
              <div style="margin-left: auto; display: flex">
                <b-form-checkbox
                  class="mb-2 mr-2"
                  size="md"
                  v-model="properties.productionDate.followInterval"
                  >{{ $t("general.followInterval") }}</b-form-checkbox
                >
                <span v-if="properties.productionDate.followInterval == true"
                  >({{ productionDateInterval }})</span
                >
              </div>
            </div>
            <date-picker
              v-model="properties.productionDate.newDate"
              :first-day-of-week="1"
              :format="this.$dayjs.localeData().longDateFormat('L')"
              value-type="YYYY-MM-DD"
              lang="en"
              :placeholder="$t('general.productionDate')"
            >
            </date-picker>
          </div>
          <div class="mb-4">
            <div style="display: flex; align-items: center">
              <b-form-checkbox
                class="mb-2"
                size="md"
                v-model="properties.deliveryDate.selected"
                >{{ $t("general.deliveryDate") }}</b-form-checkbox
              >
              <div style="margin-left: auto; display: flex">
                <b-form-checkbox
                  class="mb-2 mr-2"
                  size="md"
                  v-model="properties.deliveryDate.followInterval"
                  >{{ $t("general.followInterval") }}</b-form-checkbox
                >
                <span v-if="properties.deliveryDate.followInterval == true"
                  >({{ deliveryDateInterval }})</span
                >
              </div>
            </div>
            <date-picker
              v-model="properties.deliveryDate.newDate"
              :first-day-of-week="1"
              :format="this.$dayjs.localeData().longDateFormat('L')"
              value-type="YYYY-MM-DD"
              :placeholder="$t('general.deliveryDate')"
            >
            </date-picker>
          </div>
          <div class="mb-4">
            <div style="display: flex; align-items: center">
              <b-form-checkbox
                class="mb-2"
                size="md"
                v-model="properties.installationDate.selected"
                >{{ $t("general.installationDate") }}</b-form-checkbox
              >
              <div style="margin-left: auto; display: flex">
                <b-form-checkbox
                  class="mb-2 mr-2"
                  size="md"
                  v-model="properties.installationDate.followInterval"
                  >{{ $t("general.followInterval") }}</b-form-checkbox
                >
                <span v-if="properties.installationDate.followInterval == true"
                  >({{ installationDateInterval }})</span
                >
              </div>
            </div>
            <date-picker
              v-model="properties.installationDate.newDate"
              :first-day-of-week="1"
              :format="this.$dayjs.localeData().longDateFormat('L')"
              value-type="YYYY-MM-DD"
              :placeholder="$t('general.installationDate')"
            >
            </date-picker>
          </div>
          <div class="mb-4">
            <b-form-checkbox
              class="mb-2"
              size="md"
              v-model="properties.template.selected"
              >{{ $t("general.template") }}</b-form-checkbox
            >
            <multiselect
              v-model="properties.template.value"
              :options="templates"
              :show-labels="false"
              label="name"
              track-by="id"
              :placeholder="$t('general.template')"
            >
            </multiselect>
          </div>
          <div class="mb-4">
            <b-form-checkbox
              class="mb-2"
              size="md"
              v-model="properties.workOrderType.selected"
              >{{ $t("general.projectType") }}</b-form-checkbox
            >
            <multiselect
              v-model="properties.workOrderType.value"
              :options="workOrderTypes"
              :show-labels="false"
              label="name"
              track-by="id"
              :placeholder="$t('general.template')"
            >
            </multiselect>
          </div>
          <div class="mb-4">
            <b-form-checkbox
              class="mb-2"
              size="md"
              v-model="properties.workOrderGroup.selected"
              >{{ $t("general.group") }}</b-form-checkbox
            >
            <WorkOrderGroupTypeahead
              v-model="properties.workOrderGroup.value"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="mb-4">
            <b-form-checkbox
              class="mb-2"
              size="md"
              v-model="properties.shipper.selected"
              >{{ $t("general.shipper") }}</b-form-checkbox
            >
            <multiselect
              v-model="properties.shipper.value"
              :options="shippers"
              :show-labels="false"
              label="name"
              track-by="id"
              :placeholder="$t('general.shipper')"
            >
            </multiselect>
          </div>
          <div class="mb-4">
            <b-form-checkbox
              class="mb-2"
              size="md"
              v-model="properties.installers.selected"
              >{{ $t("general.installers") }}</b-form-checkbox
            >
            <multiselect
              v-model="properties.installers.value"
              :options="installers"
              :show-labels="false"
              label="name"
              track-by="id"
              :multiple="true"
              :placeholder="$t('general.shipper')"
            >
            </multiselect>
          </div>
          <div class="mb-4">
            <b-form-checkbox
              class="mb-2"
              size="md"
              v-model="properties.roomName.selected"
              >{{ $t("general.roomName") }}</b-form-checkbox
            >
            <b-form-group style="position: relative">
              <b-form-input
                v-model="properties.roomName.value"
                :placeholder="$t('general.roomName')"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="mb-4">
            <b-form-checkbox
              class="mb-2"
              size="md"
              v-model="properties.clientName.selected"
              >{{ $t("general.clientName2") }}</b-form-checkbox
            >
            <b-form-group style="position: relative">
              <b-form-input
                v-model="properties.clientName.value"
                :placeholder="$t('general.clientName')"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="mb-4">
            <b-form-checkbox
              class="mb-2"
              size="md"
              v-model="properties.contractor.selected"
              >{{ $t("general.contractor") }}</b-form-checkbox
            >
            <b-form-group style="position: relative">
              <b-form-input
                v-model="properties.contractor.value"
                :placeholder="$t('general.contractor')"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="mb-4">
            <b-form-checkbox
              class="mb-2"
              size="md"
              v-model="properties.projectManager.selected"
              >{{ $t("general.projectManager") }}</b-form-checkbox
            >
            <b-form-group style="position: relative">
              <b-form-input
                v-model="properties.projectManager.value"
                :placeholder="$t('general.projectManager')"
              >
              </b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer class="text-right pt-3">
      <b-button @click="cancel" class="ml-1">{{
        $t("general.cancel")
      }}</b-button>
      <b-button @click="applyBulkChange" class="ml-1" variant="primary">{{
        $t("general.apply")
      }}</b-button>
    </template>
  </b-modal>
</template>