<script>
export default {
  components: {},
  model: {
    prop: "cols",
    event: "input",
  },
  props: ["cols"],
  data() {
    return {};
  },
  created() {
    this.cols.showWorkOrderName = !localStorage.getItem(
      "workOrders.displayCols.workOrderName"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.workOrderName") ==
          "true" ?? true;

    this.cols.showClientName = !localStorage.getItem(
      "workOrders.displayCols.clientName"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.clientName") == "true" ??
        true;

    this.cols.showClientAddress = !localStorage.getItem(
      "workOrders.displayCols.clientAddress"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.clientAddress") ==
          "true" ?? true;

    this.cols.showClientCity = !localStorage.getItem(
      "workOrders.displayCols.clientCity"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.clientCity") == "true" ??
        true;

    this.cols.showClientEmail = !localStorage.getItem(
      "workOrders.displayCols.clientEmail"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.clientEmail") == "true" ??
        true;

    this.cols.showClientPhone = !localStorage.getItem(
      "workOrders.displayCols.clientPhone"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.clientPhone") == "true" ??
        true;

    this.cols.showClientState = !localStorage.getItem(
      "workOrders.displayCols.clientState"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.clientState") == "true" ??
        true;

    this.cols.showComment = !localStorage.getItem(
      "workOrders.displayCols.comment"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.comment") == "true" ??
        true;

    this.cols.showPercentCompleted = !localStorage.getItem(
      "workOrders.displayCols.percentCompleted"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.percentCompleted") ==
          "true" ?? true;
    this.cols.showLastCompletedStep = !localStorage.getItem(
      "workOrders.displayCols.lastCompletedStep"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.lastCompletedStep") ==
          "true" ?? true;
    this.cols.showWorkOrderType = !localStorage.getItem(
      "workOrders.displayCols.workOrderType"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.workOrderType") ==
          "true" ?? true;
    this.cols.showWorkOrderGroup = !localStorage.getItem(
      "workOrders.displayCols.workOrderGroup"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.workOrderGroup") ==
          "true" ?? true;
    this.cols.showRoomName = !localStorage.getItem(
      "workOrders.displayCols.roomName"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.roomName") == "true" ??
        true;
    this.cols.showShipper = !localStorage.getItem(
      "workOrders.displayCols.shipper"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.shipper") == "true" ??
        true;
    this.cols.showInstallers = !localStorage.getItem(
      "workOrders.displayCols.installers"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.installers") == "true" ??
        true;
    this.cols.showActions = !localStorage.getItem(
      "workOrders.displayCols.actions"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.actions") == "true" ??
        true;
    this.cols.showProductionDate = !localStorage.getItem(
      "workOrders.displayCols.productionDate"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.productionDate") ==
          "true" ?? true;
    this.cols.showDeliveryDate = !localStorage.getItem(
      "workOrders.displayCols.deliveryDate"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.deliveryDate") == "true" ??
        true;
    this.cols.showInstallationDate = !localStorage.getItem(
      "workOrders.displayCols.installationDate"
    )
      ? true
      : localStorage.getItem("workOrders.displayCols.installationDate") ==
          "true" ?? true;
    this.cols.showLate = !localStorage.getItem("workOrders.displayCols.isLate")
      ? true
      : localStorage.getItem("workOrders.displayCols.isLate") == "true" ?? true;
    this.cols.showCadCam = !localStorage.getItem(
      "workOrders.displayCols.cadCam"
    )
      ? false
      : localStorage.getItem("workOrders.displayCols.cadCam") == "true" ?? true;
    this.cols.showTemplate = !localStorage.getItem(
      "workOrders.displayCols.template"
    )
      ? false
      : localStorage.getItem("workOrders.displayCols.template") == "true" ??
        true;
    for (let mt of this.measureTypes) {
      let exists = localStorage.getItem("workOrders.displayCols." + mt);
      if (exists && exists == "true") {
        this.$set(this.cols, mt, true);
      } else {
        this.$set(this.cols, mt, false);
      }
    }
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    saveColDisplay() {
      localStorage.setItem(
        "workOrders.displayCols.workOrderName",
        this.cols.showWorkOrderName
      );
      localStorage.setItem(
        "workOrders.displayCols.clientName",
        this.cols.showClientName
      );
      localStorage.setItem(
        "workOrders.displayCols.percentCompleted",
        this.cols.showPercentCompleted
      );
      localStorage.setItem(
        "workOrders.displayCols.lastCompletedStep",
        this.cols.showLastCompletedStep
      );
      localStorage.setItem(
        "workOrders.displayCols.workOrderType",
        this.cols.showWorkOrderType
      );
      localStorage.setItem(
        "workOrders.displayCols.workOrderGroup",
        this.cols.showWorkOrderGroup
      );
      localStorage.setItem(
        "workOrders.displayCols.roomName",
        this.cols.showRoomName
      );
      localStorage.setItem(
        "workOrders.displayCols.shipper",
        this.cols.showShipper
      );
      localStorage.setItem(
        "workOrders.displayCols.installers",
        this.cols.showInstallers
      );
      localStorage.setItem(
        "workOrders.displayCols.actions",
        this.cols.showActions
      );
      localStorage.setItem(
        "workOrders.displayCols.productionDate",
        this.cols.showProductionDate
      );
      localStorage.setItem(
        "workOrders.displayCols.deliveryDate",
        this.cols.showDeliveryDate
      );
      localStorage.setItem(
        "workOrders.displayCols.installationDate",
        this.cols.showInstallationDate
      );
      localStorage.setItem("workOrders.displayCols.isLate", this.cols.showLate);
      localStorage.setItem(
        "workOrders.displayCols.comment",
        this.cols.showComment
      );
      localStorage.setItem(
        "workOrders.displayCols.clientAddress",
        this.cols.showClientAddress
      );
      localStorage.setItem(
        "workOrders.displayCols.clientCity",
        this.cols.showClientCity
      );
      localStorage.setItem(
        "workOrders.displayCols.clientEmail",
        this.cols.showClientEmail
      );
      localStorage.setItem(
        "workOrders.displayCols.clientPhone",
        this.cols.showClientPhone
      );
      localStorage.setItem(
        "workOrders.displayCols.clientState",
        this.cols.showClientState
      );
      localStorage.setItem(
        "workOrders.displayCols.cadCam",
        this.cols.showCadCam
      );
      localStorage.setItem(
        "workOrders.displayCols.template",
        this.cols.showTemplate
      );
    },
    setValuesColumn(measureType) {
      //this.cols[measureType] = !this.cols[measureType]

      //save in local storage
      localStorage.setItem(
        "workOrders.displayCols." + measureType,
        this.cols[measureType]
      );
    },
  },
  computed: {},
};
</script>

<template>
  <b-dropdown
    id="cogDropdown"
    right
    variant="primary"
    :text="$t('general.columns')"
  >
    <template #button-content style="padding: 0">
      <i style="font-size: 1.6em" class="bx bx-cog"></i>
    </template>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showWorkOrderName"
        ><span style="white-space: nowrap">{{
          $t("general.name")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showClientName"
        ><span style="white-space: nowrap">{{
          $t("general.clientName2")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showClientAddress"
        ><span style="white-space: nowrap">{{
          $t("general.clientAddress")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showClientCity"
        ><span style="white-space: nowrap">{{
          $t("general.clientCity")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showClientEmail"
        ><span style="white-space: nowrap">{{
          $t("general.email")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showClientPhone"
        ><span style="white-space: nowrap">{{
          $t("general.clientPhone")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showClientState"
        ><span style="white-space: nowrap">{{
          $t("general.stateProvince")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showComment"
        ><span style="white-space: nowrap">{{
          $t("general.comment")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showPercentCompleted"
        ><span style="white-space: nowrap">{{
          $t("general.advancement")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showLastCompletedStep"
        ><span style="white-space: nowrap">{{
          $t("general.status")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showWorkOrderType"
        ><span style="white-space: nowrap">{{
          $t("general.type")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showWorkOrderGroup"
        ><span style="white-space: nowrap">{{
          $t("general.group")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showRoomName"
        ><span style="white-space: nowrap">{{
          $t("general.room")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showProductionDate"
        ><span style="white-space: nowrap">{{
          $t("general.showProductionDate")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showDeliveryDate"
        ><span style="white-space: nowrap">{{
          $t("general.showDeliveryDate")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showInstallationDate"
        ><span style="white-space: nowrap">{{
          $t("general.showInstallationDate")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showLate"
        ><span style="white-space: nowrap">{{
          $t("general.late")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showTemplate"
        ><span style="white-space: nowrap">{{
          $t("general.template")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showShipper"
        ><span style="white-space: nowrap">{{
          $t("general.shipper")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showInstallers"
        ><span style="white-space: nowrap">{{
          $t("general.installers")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showCadCam"
        ><span style="white-space: nowrap">{{
          $t("general.showCadCAm")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form v-for="(mt, index) in measureTypes" :key="index">
      <b-form-checkbox
        @change="setValuesColumn(mt)"
        v-model="cols[mt]"
        class="mr-3"
        size="md"
        switch
        ><span style="white-space: nowrap">{{
          $t("general." + mt)
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        @change="saveColDisplay"
        class="mr-3"
        size="md"
        switch
        v-model="cols.showActions"
        ><span style="white-space: nowrap">{{
          $t("general.actions")
        }}</span></b-form-checkbox
      >
    </b-dropdown-form>
  </b-dropdown>
</template>
